/* Ant css overrides */
/* Use this to globally override the ant global css.  */

h1.ant-typography {
  font-size: 40px !important;
  /*font-family: Poppins, Lato, sans-serif;*/
  font-family: Lato, sans-serif;
  /*font-weight: 700 !important;*/
}

h2.ant-typography {
  font-size: 28px !important;
  font-family: Lato, sans-serif;
  /*font-family: Poppins, Lato, sans-serif;*/
  /*font-weight: 700 !important;*/
}

h3.ant-typography {
  font-size: 24px !important;
  /*font-family: Poppins, Lato, sans-serif;*/
  /*font-weight: 700 !important;*/
}

h4.ant-typography {
  font-size: 20px !important;
  /*font-family: Poppins, Lato, sans-serif;*/
  /*font-weight: 700 !important;*/
}

/* Use component properties instead. */
/*.ant-typography-secondary {*/
/*  color: rgba(105, 105, 105, 1) !important;*/
/*}*/

/* Tabs for profiles */
/* Put here because this needs to override the classes */
/* Other styles should either be a styled component or a css module */

/* PROFILE TABS */
/*region Profile Tabs */
.profile-tabs {
  height: 100%;
}

.profile-tabs div.ant-tabs-bar {
  border-right-color: transparent;
}

.profile-tabs div.ant-tabs-tab {
  /*color: rgb(138, 155, 168);*/
  /*font-weight: 400;*/
  font-size: 16px !important;
  text-align: left !important;
  padding-right: 24px !important;
  margin-bottom: 0 !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  background-color: transparent;
}

.profile-tabs div.ant-tabs-tab-active {
  background-color: white;
}

.profile-tabs div.ant-tabs-nav-wrap {
  /*padding-top: 16px;*/
  background-color: transparent;
}

.profile-tabs div.ant-tabs-ink-bar {
  display: none !important;
}
/*endregion*/

/* BOOKING TABS */

/*region Booking Tabs */
.booking-tabs {
}

.booking-tabs div.ant-tabs-bar {
  border-right-color: transparent;
}

.booking-tabs div.ant-tabs-tab {
  /*color: rgb(138, 155, 168);*/
  /*font-weight: 400;*/
  font-size: 16px !important;
  text-align: left !important;
  margin-bottom: 4px !important;
  margin-right: 8px !important;
  padding: 10px 32px 10px 32px !important;
  border-radius: 4px;
  background-color: white;
  transition: none !important;
  font-weight: 700 !important;
}

.booking-tabs div.ant-tabs-left-content {
  border-left: none !important;
  padding-left: 24px !important;
}

.booking-tabs div.ant-tabs-tab:hover {
  background-color: rgba(138, 155, 168, 0.1);
  color: black;
}

.booking-tabs div.ant-tabs-tab-active {
  background-color: #3495c9;
  color: white;
}

.booking-tabs div.ant-tabs-tab-active:hover {
  background-color: #3495c9;
  color: white;
}

.booking-tabs div.ant-tabs-nav-wrap {
  /*padding-top: 16px;*/
  background-color: transparent;
}

.booking-tabs div.ant-tabs-ink-bar {
  display: none !important;
}
/*endregion*/

div.ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 100%;
}

.ant-input:focus {
  box-shadow: 0 0 0 4px rgba(19, 124, 189, 0.2) !important;
}

.ant-input-number-focused {
  box-shadow: 0 0 0 4px rgba(19, 124, 189, 0.2) !important;
}

/* Ant Buttons. Overriding animation and active on click styles */
.ant-btn {
  transition: none !important;
}

.ant-tooltip-inner {
  background-color: rgb(36, 36, 36) !important;
}

/* Design / experimental */
.ant-checkbox-checked .ant-checkbox-inner {
  /*background-color: blue !important;*/
  /*border-color: red !important;*/
}

.default-input input.ant-input-lg {
  font-size: 16px !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: gray !important;
}

.ant-modal-root {
  z-index: 1010;
  position: absolute;
}

.antd-img-crop-modal .ant-btn {
  color: green !important;
  border: none;
}

.antd-img-crop-modal .ant-btn-primary {
  background-color: darkgreen !important;
  color: white !important;
}
