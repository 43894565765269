/* Google Map css overrides */
/* Use this to globally override the ant global css.  */

.location-search-input {
  border-radius: 4px;
  border: 1px solid #d9d9d9;

}

.location-search-input:focus {
  border-color: #2e82b0;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 4px rgba(16, 107, 163, 0.2);
  box-shadow: 0 0 0 4px rgba(19, 124, 189, 0.2) !important;
}

.location-search-input:hover {
  border-color: #2e82b0;
}